import axios from 'axios';
import React, { useCallback, useEffect, useState } from 'react';
import { useApplicationContext } from '../ApplicationContext';
import { Outlet } from 'react-router-dom';
import { FileFormats } from '../lib/constants';
import { enqueueSnackbar } from 'notistack';
import useReportService from '../hooks/useReportService';

const ReportContext = React.createContext(null);

const useReportContext = () => {
    const context = React.useContext(ReportContext);

    if (context === undefined) {
        throw new Error('useReportContext must be used within an ReportContext.Provider');
    }
    return context;
};

export interface ReportComment {
    comment: string;
    year: number;
    month: number;
    communityId: string;
    reportId: string;
}

// export const BASIC_COMMUNITY_FILTER = {
//     $schema: 'https://powerbi.com/product/schema#basic',
//     target: {
//         table: 'Facility',
//         column: 'Community',
//     },
//     operator: 'In',
//     values: [],
//     filterType: models.FilterType.Basic,
//     displaySettings: { isHiddenInViewMode: true },
// };
//
// export const BASIC_ALL_COMMUNITY_FILTER = {
//     $schema: 'https://powerbi.com/product/schema#basic',
//     target: {
//         table: 'Facility',
//         column: 'Community',
//     },
//     operator: 'All',
//     values: [],
//     filterType: models.FilterType.Basic,
//     displaySettings: { isHiddenInViewMode: true },
// };

const INITIAL_FILTER = {
    communities: [],
};

const Provider = () => {
    const { selectedCompanyId, isOwner } = useApplicationContext();
    const { getReportComment, addReportComment, deleteReportComment } = useReportService();
    const [globalFilter, setGlobalFilter] = useState<any>({ communities: [] });

    const [exportProgress, setExportProgress] = useState(null);
    const [reportRef, setReportRef] = useState(null);

    /*
     * Global Filter Logic
     * */

    useEffect(() => {
        setGlobalFilter(INITIAL_FILTER);
    }, [selectedCompanyId]);

    // TODO: more this to the apply button when additional filters are added
    // useEffect(() => {
    //     const filter = clone(BASIC_COMMUNITY_FILTER);
    //
    //     if (globalFilter.communities.length > 0) {
    //         filter.values = globalFilter.communities.map((c) => c.name);
    //         reportRef?.embed.updateFilters(models.FiltersOperations.Replace, [filter]);
    //     } else {
    //         reportRef?.embed.updateFilters(models.FiltersOperations.Replace, [BASIC_ALL_COMMUNITY_FILTER]);
    //     }
    // }, [reportRef, globalFilter]);

    const setGlobalFilterValue = useCallback((k, v) => {
        setGlobalFilter((prev: any) => {
            return { ...prev, [k]: v };
        });
    }, []);

    /*
     * Comment Logic
     * */

    const onFail = (message: string, error: string) => {
        enqueueSnackbar(`${message}: ${error}`, { variant: 'error' });
        setExportProgress(null);
    };

    const getComment = useCallback(
        async (reportId: string, year: number, month: number, communityId: string) => {
            const date = new Date(year, month, 1);
            return getReportComment(reportId, date.toISOString(), communityId);
        },
        [getReportComment],
    );

    const addComment = useCallback(
        async ({ comment = '', communityId, reportId, year, month }: ReportComment) => {
            addReportComment({ comment, communityId, reportId, year, month }).catch((e) => onFail('Failed to add comment', e));
        },
        [addReportComment],
    );

    const deleteComment = useCallback(
        async (commentId: any) => {
            deleteReportComment(commentId).catch((e) => onFail('Failed to remove comment', e));
        },
        [deleteReportComment],
    );

    /*
     * Export Logic
     * */

    const downloadReportPDF = useCallback(async (exportId: string, reportId: string, fileName: string) => {
        const downloadQueryParams = new URLSearchParams();
        const downloadUrl = `/EmbedInfo/GetExportedFile`;

        downloadQueryParams.append('reportId', reportId);
        downloadQueryParams.append('exportId', exportId);
        await axios
            .get(downloadUrl, {
                params: downloadQueryParams,
                responseType: 'blob',
            })
            .then(async (response) => {
                if (response.status === 200) {
                    const fileStream = response.data;

                    const link = document.createElement('a');
                    const objectURL = URL.createObjectURL(fileStream);
                    link.href = objectURL;
                    link.download = fileName;
                    link.click();
                    link.remove();
                    URL.revokeObjectURL(objectURL);
                    setExportProgress(101);
                    setTimeout(() => {
                        setExportProgress(null);
                    }, 2000);
                } else {
                    console.error(`Failed to get exported file: ${response.status}`);
                }
            })
            .catch((e) => onFail('Failed to export', e));
    }, []);

    const pollExportRequest = useCallback(
        async (exportId: string, reportId: string) => {
            const pollQueryParams = new URLSearchParams();
            const pollUrl = `/EmbedInfo/PollExportRequest`;

            pollQueryParams.append('reportId', reportId);
            pollQueryParams.append('exportId', exportId);
            const poll = async () => {
                await axios
                    .post(pollUrl, null, {
                        params: pollQueryParams,
                    })
                    .then((response) => {
                        if (response.data.status !== 'Succeeded') {
                            setExportProgress(response.data.percentComplete);
                            setTimeout(poll, 250);
                        } else {
                            const reportName = response.data.reportName;
                            const yyyyMMdd = new Date().toISOString().split('T')[0];
                            downloadReportPDF(exportId, reportId, `${reportName}_${yyyyMMdd}.pdf`);
                        }
                    })
                    .catch((e) => onFail('Failed to export', e));
            };
            await poll();
        },
        [downloadReportPDF],
    );

    const exportReport = useCallback(
        async (reportId) => {
            if (reportRef) {
                setExportProgress(0);
                const postQueryParams = new URLSearchParams();
                postQueryParams.append('reportId', reportId);
                postQueryParams.append('companyId', selectedCompanyId?.toString());
                postQueryParams.append('isOwner', isOwner?.toString());
                postQueryParams.append('fileFormat', FileFormats.PDF);
                const bookmark = await reportRef?.embed.bookmarksManager.capture();
                postQueryParams.append('bookmarkState', bookmark.state);
                const postUrl = `/EmbedInfo/PostExportRequest`;

                return await axios
                    .post(postUrl, null, {
                        params: postQueryParams,
                    })
                    .then((response) => {
                        pollExportRequest(response.data, reportId);
                    })
                    .catch((e) => onFail('Failed to export', e));
            }
        },
        [selectedCompanyId, pollExportRequest, isOwner, reportRef],
    );

    return (
        <ReportContext.Provider
            value={{
                exportReport,
                exportProgress,
                reportRef,
                setReportRef,
                getComment,
                addComment,
                deleteComment,
                globalFilter,
                setGlobalFilterValue,
            }}
        >
            <Outlet />
        </ReportContext.Provider>
    );
};

const ReportProvider = Provider;

export { ReportContext, ReportProvider, useReportContext };
